<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchData"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.settleData.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.settleData.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.settleData.boardData.emptyDesc"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="정산 정보 가져오기"
          @onClickBtn="viewModel.onClickGetSettle()">
        </Button>
      </template>
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import SettleListViewModel from '@/views/store/settle/viewModel/SettleListViewModel'

export default {
  name:'SettleList',
  components:{
    PageWithLayout,
    Tab,
    Search,
    Board,
    Button,
  },
  beforeMount(){
    const step = this.$route.query.step;
    this.viewModel.init(step);
  },
  data(){
    return{
      viewModel: new SettleListViewModel(),
    }
  },
  computed:{
    isShowSearch(){
      return this.viewModel.tabData.value === 'PENDING' || this.viewModel.tabData.value === 'DONE'
    }
  },
}
</script>