import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertIdToText, setDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';

import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class SettleListViewModel {
  constructor() {
    this.searchData = [
      {
        title:'법인명/상호',
        type: 'Selectbox',
        dataList:[],
        maxWidth:"300px",
        isFull:true,
        value:null
      }
    ]
    this.settleData = {
      dataList: [],
      boardData : {
        title:'일일 대사 정보',
        drawDataList: [
          {
            title:'No.',
            width:'72px',
            value:'id',
          },
          // {
          //   title:'정산 ID',
          //   width:'72px',
          //   value:'affiliateId',
          // },
          {
            title:'정산대사일자',
            width:'120px',
            value:'settlementAt',
            valueCustom:[
              {
                type: 'value',
                value:'settlementAt',
                ifNot:{
                  value:'settlementAt',
                  condition:null
                },
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              { 
                type: 'text',
                value: ' (',
                ifNot:{
                  value:'settlementAt',
                  condition:null
                },
              },
              {
                type: 'value',
                value:'settlementAt',
                ifNot:{
                  value:'settlementAt',
                  condition:null
                },
                filter:{
                  name:'getDayToWeek',
                },
              },
              { 
                type: 'text',
                value: ')',
                ifNot:{
                  value:'settlementAt',
                  condition:null
                },
              },
            ],
            isAlignLeft: true,
            emptyValueText:'-',
          },
          {
            title:'거래건수',
            width:'72px',
            value:'transactionCount',
            emptyValueText:'0',
          },
          {
            title:'총 부가가치세',
            width:'',
            value:'vatAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0',
          },
          {
            title:'총 수수료',
            width:'',
            value:'feeAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0',
          },
          {
            title:'총 판매금액',
            width:'',
            value:'totalAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0',
          },
          {
            title:'최종 정산금',
            width:'',
            value:'totalPaidAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0',
          },
          {
            title:'정산상태',
            width:'100px',
            value:'status',
            filter:{
              name:'convertIdToText',
              value:'settle_status'
            },
            class: { classColorFilter:{ name:'convertIdToColor', dataListName:'settle_status', prev:'tc' } },
          },
        ],
        option:{
          isTotal: true,
          isSize: true,
          // rowIdValue: 'settle_id'
        },
        emptyDesc:'법인명/상호를 선택하고 검색해주세요'
      },
    };
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getStoreList();
  }
  onClickRow(rowId,rowData){
    // router.push({ name: 'STORE_SETTLE_DETAIL', params: { id: rowId, step : this.tabData.value } });
  }
  onClickGetSettle(){
    this.postSettleList();
  }
  onSearch(){
    this.getSettleList();
  }
  getStoreList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.SOTRE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      const dataList = setDataList(resultData,'id','name');
       this.searchData[0].dataList = dataList;
      this.searchData[0].value = dataList[0].id;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postSettleList(){
    const path = `${apiPath.SETTLEMENT_SET}`;
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      // this.tradeData.dataList = resultData.data
      // this.tradeData.paginationData.totalCount = resultData.total;
      // this.tradeData.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getSettleList(){
    const query = makeQueryStringByObject(this.searchParams)
    const path = `${apiPath.SETTLEMENT_LIST.format(this.searchData[0].value)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      // const pagination = success.data.pagination;
      this.settleData.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
      this.settleData.boardData.emptyDesc = `${convertIdToText(this.searchData[0].value,this.searchData[0].dataList)}의 검색결과가 없습니다`;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}